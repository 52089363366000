import ModalConfirm from "elements/modalConfirm";
import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";

function UniversityList() {
  // init
  const navigate = useNavigate();
  const [reload, setReload] = useState(new Date());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("unr")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const callAction = (data) => {
    if (data.type == "Add" || data.type == "add") {
      navigate("/university/add");
    } else if (data.type == "Edit") {
      navigate("/university/edit/" + data.item._id);
    } else if (data.type == "View") {
      navigate("/university/view/" + data.item._id);
    } else if (data.type === "Delete") {
      setDeleteData(data.item);
    }
  };

  //api
  const DeleteRecord = (item) => {
    setLoading(true);
    let data = {
      url: "university",
      method: "DELETE",
      query: `/${item._id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been removed");
        setDeleteData(null);
        setReload(new Date());
        setLoading(false);
      }
    });
  };

  return (
    permission.includes("unr") && (
      <>
        <AgTable
          module="adminUniversity"
          reload={reload}
          filterData={{
            type: { key: "type", value: "" },
            country: { key: "country", value: "" },
            province: { key: "province", value: "" },
            city: { key: "city", value: "" },
            published: { key: "isPublished", value: "" },
            dateA: { key: "startDate", value: "", title: "Created Date From" },
            dateB: { key: "endDate", value: "", title: "Created Date To" },
          }}
          actionPermission={common.accessTable(permission, [
            "unc",
            "unu",
            "unr",
            "und",
          ])}
          onAction={(data) => callAction(data)}
        />
        {deleteData !== null && (
          <ModalConfirm
            loading={loading}
            onYes={() => DeleteRecord(deleteData)}
            onNo={() => setDeleteData(null)}
          />
        )}
      </>
    )
  );
}

export default UniversityList;
