import React, { useRef } from "react";
// import { Editor } from "@tinymce/tinymce-react";
import { Editor } from "react-draft-wysiwyg";
import AmlHashForm from "aml-hash-form";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
// includes
import FieldSelect from "elements/fieldSelect";
import StatusBar from "elements/statusBar";
import { api, common, config, types } from "helpers";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

function Form() {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [imageErr, setImageErr] = useState("");
  const [descErr, setDescErr] = useState("");
  const [error, setError] = useState("");
  const [finder, setFinder] = useState({
    university: true,
    course: true,
    agent: true,
  });
  const [effectField, setEffectField] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageEdit, setImageEdit] = useState(false);
  const [imagePath, setImagePath] = useState("");

  // validate
  const formFields = {
    title: { validate: ["req"] },
    shortDescription: { validate: ["req", "max:150"] },
    description: { value: EditorState.createEmpty() },
    image: {},
    category: { validate: ["req"] },
    metaKey: {},
    metaTitle: {},
    metaDescription: {},
    authorName: { validate: ["req"] },
    isActive: {},
  };
  const {
    fields,
    errors,
    setValue,
    handleChange,
    handleSubmit,
    setMultiValue,
  } = AmlHashForm(formFields);

  useEffect(() => {
    getCategory();
    if (id) {
      getInfo();
    }
  }, []);

  const getCategory = () => {
    let data = {
      url: "blogCategory",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res?.data?.data;
        let list = [];
        result?.length > 0 &&
          result?.map((item) => {
            let val = { label: item.name, value: item._id };
            list.push(val);
          });
        setCategoryList(list);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const getInfo = () => {
    let data = {
      url: "blogContent",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;

        let status = types.status.filter((item) => {
          return item.value === result.isActive;
        });

        let category = {
          label: result.categoryId.name,
          value: result.categoryId._id,
        };

        let image = result?.image?.split("/");
        setImagePath(image[1]);
        setMultiValue({
          title: result.title,
          shortDescription: result.shortDescription,
          authorName: result.authorName,
          description: setHtml(result.description),
          category: category,
          metaKey: result.metaKey,
          metaTitle: result.metaTitle,
          metaDescription: result.metaDescription,
          isActive: status[0],
        });
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  // const isImageFile = (file) => {
  //   const extension = file.name.split(".").pop().toLowerCase();
  //   const FileExtensions = ["jpg", "jpeg", "doc", "pdf"];
  //   FileExtensions.includes(extension);
  //   return FileExtensions.includes(extension);
  // };

  // handler
  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);

    const extension = selectedImage.name.split(".").pop().toLowerCase();
    const FileExtensions = ["jpg", "jpeg", "png", "gif"];

    let data = {
      url: FileExtensions.includes(extension)
        ? "common_upload"
        : "common_videoupload",
      method: "POST",
      body: formData,
      ssr: true,
      cType: 6,
    };

    return api.call(data, (res) => {
      let response = res.data;
      if (res.status == 200) {
        let banner = response?.data?.link;

        return banner;
      } else {
        common.notify("E", "Error In Uploading Image ,please try again");
      }
    });
  };

  const blogSubmit = async () => {
    try {
      let isValid = handleSubmit();
      if (imagePath == "") {
        setImageErr("Please select a image");
      } else {
        setImageErr("");
      }
      if (getLength("description") == 1) {
        setDescErr("Please Write Description");
      } else {
        setDescErr("");
      }
      if (isValid) {
        if (selectedImage != null) {
          let imagePath = await uploadFile();

          if (imagePath.status === 200) {
            onSubmit(imagePath?.data?.link[0]);
          }
        } else {
          onSubmit(imagePath);
        }
      }
    } catch (error) {
      common.notify("E", "Error in File upload, please try again ");
    }
  };

  const onSubmit = (imagePath) => {
    setLoading(true);

    let data = {};
    let params = {
      title: fields.title,
      shortDescription: fields.shortDescription,
      description: getHtml("description"),
      image: imagePath,
      categoryId: fields?.category?.value,
      authorName: fields.authorName,
      metaKey: fields.metaKey,
      metaTitle: fields.metaTitle,
      metaDescription: fields.metaDescription,
      isActive: fields.isActive.value,
    };
    if (id) {
      data = {
        url: "blogContent",
        method: "PUT",
        query: `/${id}`,
        body: { ...params },
      };
    } else {
      data = {
        url: "blogContent",
        method: "POST",
        body: { ...params },
      };
    }
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify(
          "S",
          `Blog Details  ${!id ? "created" : "updated"} successfully`
        );
        navigate("/blog");
      } else if (res.data.error) {
        common.notify("E", res.data.error);
      } else {
        common.notify("E", "Something went wrong");
      }
      setLoading(false);
    });
  };
  //handle Image

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePath(file.name);
      setImageErr("");
    } else {
      setSelectedImage(null);
      setImagePath(null);
    }
  };
  const deleteImageFile = () => {
    setSelectedImage(null);
    setImagePath(null);
  };
  // support
  // const getHtml = (val) => {
  //   return draftToHtml(convertToRaw(fields[val].getCurrentContent()));
  // };
  const getHtml = (val) => {
    return fields[val]?.getCurrentContent
      ? draftToHtml(convertToRaw(fields[val].getCurrentContent()))
      : "";
  };
  const setHtml = (val) => {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(val))
    );
  };

  const getLength = (val) => {
    return common.htmlToString(getHtml(val)).length;
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate("/blog")}
          />
          <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} Blog</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="container-fluid">
            <h2 className="form-title">Blog</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Blog Title"
                    className="form-control"
                    value={fields.title}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.title}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Author Name</label>
                  <input
                    type="text"
                    name="authorName"
                    placeholder="Author Name"
                    className="form-control"
                    value={fields.authorName}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.authorName}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Select Category</label>
                  <Select
                    className="custom-select-box"
                    value={fields.category}
                    options={categoryList}
                    isClearable={false}
                    onChange={(data) => {
                      setMultiValue({ category: data });
                    }}
                  />
                  <p className="error-txt">{errors.category}</p>
                </div>
                {/* <div className="col-md-6 mb-4">
                  <label>Blog Image</label>
                  <input
                    type="file"
                    name="image"
                    className="form-control"
                    // value={imagePath}
                    onChange={(e) => handleImage(e)}
                  />
                  <p className="error-txt">{errors.image}</p>
                </div> */}
                <div className="col-md-6 mb-4">
                  <label>Blog Image/Video</label>
                  <div className="file-upload-inp">
                    <input
                      type="file"
                      name="image"
                      id="fileInput"
                      className="form-control"
                      onChange={(e) => handleImage(e)}
                      value={""}
                      // style={{ display: "none" }}
                    />

                    {/* <label htmlFor="fileInput" className="file-label">
                      {selectedImage || id ? imagePath : "Choose a file"}
                    </label> */}
                  </div>
                  {imagePath ? (
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <span> {imagePath}</span>

                      <img
                        src={common.loadImg("iconly-dark-delete.svg")}
                        className="et-cp"
                        onClick={deleteImageFile}
                      />
                    </div>
                  ) : null}

                  <p className="error-txt">{imageErr}</p>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Short Description (max 150 characters)</label>
                  <textarea
                    type="text"
                    name="shortDescription"
                    placeholder="Short Description"
                    className="form-control"
                    value={fields.shortDescription}
                    onChange={handleChange}
                    rows="4"
                    cols="50"
                    maxlength="150"
                  />
                  <p className="error-txt">{errors.shortDescription}</p>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Description</label>
                  <Editor
                    editorState={fields.description}
                    onEditorStateChange={(e) =>
                      setMultiValue({ description: e })
                    }
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ["blockType", "list"],
                      blockType: {
                        inDropdown: true,
                        options: ["H1", "H2", "H3", "H4", "H5", "H6"],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered"],
                      },
                    }}
                  />
                  <p className="error-txt">
                    {getLength("description") > 10 ? "" : descErr}
                  </p>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Meta Description</label>
                  <textarea
                    type="text"
                    name="metaDescription"
                    placeholder="Meta Description"
                    className="form-control"
                    value={fields.metaDescription}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Meta Key</label>
                  <input
                    type="text"
                    name="metaKey"
                    placeholder="Meta Key"
                    className="form-control"
                    value={fields.metaKey}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Meta Title</label>
                  <input
                    type="text"
                    name="metaTitle"
                    placeholder="Meta Title"
                    className="form-control"
                    value={fields.metaTitle}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Status</label>
                  <Select
                    className="custom-select-box"
                    value={fields.isActive}
                    options={types.status}
                    isClearable={false}
                    onChange={(data) => {
                      setMultiValue({ isActive: data });
                    }}
                  />
                </div>{" "}
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                // onClick={onSubmit}
                onClick={blogSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Save & Update"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Form;
