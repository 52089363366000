import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
// includes
import CommonStudentView from "includes/studentView";
import { common } from "helpers";

function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Student View</h1>
      </div>
      <div className="content-wrap">
        <CommonStudentView id={id} formType="student" formModule="all" type="view" />
      </div>
    </Fragment>
  );
}
export default View;
