import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";

function EnquiryList() {
  // init
  const navigate = useNavigate();
  const [reload, setReload] = useState(new Date());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("eqr")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const callAction = (data) => {
   if (data.type == "Export" || data.type == "export") {
      exportRecord(data);
    } else if(data.type == "View"){
      navigate("/enquiry/view/" + data.item._id);
    } else if(data.type == "massdelete"){
      DeleteRecord(data.item);
    }
  };

  const DeleteRecord = (item) => {
    let data = {
      url: "enquiry",
      query: "/delete",
      method: "PUT",
      body: {ids: item},
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Records has been removed");
        setReload(new Date());
      }
    });
  };

  //api
  const exportRecord = (val) => {
    let data = {
      url: "enquiry_export",
      method: "GET",
      query: val.query,
      responseType: "file",
      fileName: "enquiry",
    };
    api.call(data, (res) => {});
  };

  return (
    permission.includes("eqr") && (
      <>
        <AgTable
          module="adminEnquiry"
          reload={reload}
          filterData={{dateA: { key: "startDate", value: "", title: "Created Date From" }}}
          actionPermission={common.accessTable(permission, ["eqr", "eqc", "equ", "eqd"])}
          onAction={(data) => callAction(data)}
        />
      </>
    )
  );
}

export default EnquiryList;
