import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-modal";
// includess
import FieldSelect from "elements/fieldSelect";
import { api, common } from "helpers";
import StatusBar from "elements/statusBar";

function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [myModal, setMyModal] = useState(null);
  // state
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [result, setResult] = useState(null);
  const [salesPerson, setSalesPerson] = useState({});
  const [respAgent, setRespAgent] = useState({});
  const [verified, setVerified] = useState(false);
  const [createLoginAuthorized, setCreateLoginAuthorized] = useState(false);
  const [permission] = useState(common.getUserPermission());

  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "agent",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        // sales person
        const {
          salesPersonId,
          isVerified,
          coordinatorId,
          createLoginAuthorized,
        } = res.data;
        setSalesPerson({
          label: salesPersonId?.salesPersonName,
          value: salesPersonId?.salesPersonId,
        });
        setRespAgent({
          label: coordinatorId?.email,
          value: coordinatorId?._id,
        });
        setVerified(isVerified);
        setCreateLoginAuthorized(createLoginAuthorized);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  //Checked agents before deactivate coordinator
  const agentUpdate = (field, value) => {
    if (
      field == "isPromotor" &&
      value == false &&
      result?.getHisAgents &&
      result?.getHisAgents.length > 0
    ) {
      setMyModal("confirm");
      setResult({
        ...result,
        isPromotor: true,
      });
    } else {
      setLoading("status");
      let data = {
        url: "agent",
        method: "PUT",
        query: `/${id}`,
        body: {
          [field]: value,
        },
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          getInfo();
          common.notify("S", "Record has been updated");
        } else {
          common.notify("E", "Unable to update data please try again");
        }
        setLoading(null);
      });
    }
  };

  // functions
  const closeModal = () => {
    setMyModal(null);
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Agent View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-view-content-wrap">
                {/* Inner Content */}
                <div className="et-vc-info et-view-border-bottom">
                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Status & Activation
                    </h3>
                  </div>
                  <ul className="et-vc-list row">
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Active</p>
                        <p className="et-field-value">
                          <Switch
                            defaultChecked={result.isActive}
                            name="isActive"
                            color="secondary"
                            onChange={(e) =>
                              agentUpdate("isActive", e.target.checked)
                            }
                            disabled={permission.includes("agu") ? false : true}
                          />
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Verify</p>
                        <p className="et-field-value">
                          <Switch
                            defaultChecked={verified}
                            name="isVerified"
                            color="secondary"
                            disabled={verified}
                            onChange={(e) => {
                              setVerified(true);
                              if (!verified) {
                                agentUpdate("isVerified", true);
                              }
                            }}
                          />
                        </p>
                      </div>
                    </li>

                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Enable ConnectedAgent</p>
                        <p className="et-field-value">
                          <Switch
                            defaultChecked={result.isConAgent}
                            name="isConAgent"
                            color="secondary"
                            onChange={(e) =>
                              agentUpdate("isConAgent", e.target.checked)
                            }
                            disabled={permission.includes("agu") ? false : true}
                          />
                        </p>
                      </div>
                    </li>

                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Responsible Person</p>
                        <p className="et-field-value">
                          <FieldSelect
                            api="common_sales_person"
                            query="?offset=0&limit=1000"
                            get="EmployeeName"
                            set="EmployeeId"
                            value={salesPerson}
                            placeholder="Select the responsible person"
                            render={"yes"}
                            clearable={false}
                            finder={false}
                            multi={false}
                            onSelect={(data) => {
                              setSalesPerson(data);
                              agentUpdate("salesPersonId", data.value);
                            }}
                            disabled={permission.includes("agu") ? false : true}
                          />
                        </p>
                      </div>
                    </li>

                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Agent Agreement</p>
                        {!result?.agreement && (
                          <p className="et-vc-label">No document found</p>
                        )}
                        {result?.agreement && (
                          <div className="row mb-3">
                            <div className="et-field-value col-md-9">
                              <span
                                className="et-link"
                                onClick={() =>
                                  common.openCloudUrl(result?.agreement)
                                }
                              >
                                {common.fileName(result?.agreement)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>

                    {result.isActive && verified && !respAgent?.label && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Is Coordinator</p>
                          <p className="et-field-value">
                            <Switch
                              //defaultChecked={result.isPromotor}
                              checked={result.isPromotor}
                              name="isPromotor"
                              color="secondary"
                              onChange={(e) =>
                                agentUpdate("isPromotor", e.target.checked)
                              }
                              disabled={permission.includes("agu") ? false : true}
                            />
                          </p>
                        </div>
                      </li>
                    )}
                    {result.isActive && verified && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">
                            Create Logins Authorized
                          </p>
                          <p className="et-field-value">
                            <Switch
                              //defaultChecked={result.isPromotor}
                              checked={result.createLoginAuthorized}
                              name="createLoginAuthorized"
                              color="secondary"
                              onChange={(e) =>
                                agentUpdate(
                                  "createLoginAuthorized",
                                  e.target.checked
                                )
                              }
                              disabled={permission.includes("agu") ? false : true}
                            />
                          </p>
                        </div>
                      </li>
                    )}

                    {result.isActive &&
                      verified &&
                      result?.isPromotor == false && (
                        <li className="col-md-4 et-view-border-bottom et-view-border-right">
                          <div className="et-list-inner">
                            <p className="et-field-name">
                              Responsible Coordinator
                            </p>
                            <p className="et-field-value">
                              <FieldSelect
                                api="agent"
                                query={`?offset=0&limit=1000&agentId=${result._id}`}
                                get="email"
                                set="_id"
                                value={respAgent}
                                placeholder="Select the Coordinator"
                                render={"yes"}
                                clearable={true}
                                finder={false}
                                multi={false}
                                onSelect={(data) => {
                                  setRespAgent(data);
                                  agentUpdate(
                                    "coordinatorId",
                                    data?.value || null
                                  );
                                }}
                                disabled={permission.includes("agu") ? false : true}
                              />
                            </p>
                          </div>
                        </li>
                      )}
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Business Information
                    </h3>
                  </div>
                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Profile</p>
                        <p className="et-field-value mb-2">
                          {`${result.firstName} ${result.lastName}`}
                        </p>
                        <img
                          className="et-logo"
                          src={
                            result.logo
                              ? common.cloudImg(result.logo)
                              : common.loadImg("no_img.png")
                          }
                        />
                      </div>
                    </li>

                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Business Info</p>
                        <p className="et-field-value mb-2">
                          {`${result.businessName}`}
                        </p>
                        <img
                          className="et-logo"
                          src={
                            result.businessLogo
                              ? common.cloudImg(result.businessLogo)
                              : common.loadImg("no_img.png")
                          }
                        />
                      </div>
                    </li>

                    {[
                      {
                        name: "Email",
                        key: "email",
                        type: "string",
                      },
                      {
                        name: "Designation",
                        key: "designation",
                        type: "string",
                      },
                      {
                        name: "Business Address",
                        key: "businessAddress",
                        type: "string",
                      },
                      {
                        name: "Business Contact Number",
                        key: "businessContactNo",
                        type: "mobile",
                      },
                      {
                        name: "Business Registration Number",
                        key: "businessRegNumber",
                        type: "string",
                      },
                      {
                        name: "Your Recruitment Methods",
                        key: "recruitMethod",
                        type: "string",
                      },
                      {
                        name: "Number of students recruited for last year",
                        key: "noOfStudentRecruitLastYear",
                        type: "string",
                      },
                      {
                        name: "Number of staffs working",
                        key: "noOfStaffWorking",
                        type: "string",
                      },
                      {
                        name: "Other countries or branches",
                        key: "otherCountryBranch",
                        type: "string",
                      },
                      {
                        name: "Country",
                        key: "countryOfOperation",
                        type: "select",
                      },
                      {
                        name: "Province",
                        key: "province",
                        type: "select",
                      },
                      {
                        name: "City",
                        key: "city",
                        type: "select",
                      },
                    ].map((i, k) => (
                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                        key={k}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">{i.name}</p>
                          <p className="et-field-value">
                            {i.type === "mobile" ? (
                              <span>
                                {`${common.hideMobile(result.businessContactNo)}`}
                              </span>
                            ) : (
                              i.key == "email" ? common.hideEmail(result[i.key]) : common.readable(i.type, result[i.key])
                            )}
                          </p>
                        </div>
                      </li>
                    ))}

                    <li className="col-md-8 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Description</p>
                        <p className="et-field-value">
                          {result.description || "-"}
                        </p>
                      </div>
                    </li>
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">Document</h3>
                  </div>

                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-6 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Business Registration</p>
                        {!result.businessRegDocs && (
                          <p className="et-vc-label">No document found</p>
                        )}
                        {result?.businessRegDocs?.map((iB, kB) => (
                          <div key={kB} className="row mb-3">
                            <div className="et-field-value col-md-9">
                              <span
                                className="et-link"
                                onClick={() => common.openCloudUrl(iB)}
                              >
                                {common.fileName(iB)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>

                    <li
                      className={`col-md-6 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Proof of Identity</p>
                        {!result?.proofIdentityDocs && (
                          <p className="et-vc-label">No document found</p>
                        )}
                        {result?.proofIdentityDocs?.map((iB, kB) => (
                          <div key={kB} className="row mb-3">
                            <div className="et-field-value col-md-9">
                              <span
                                className="et-link"
                                onClick={() => common.openCloudUrl(iB)}
                              >
                                {common.fileName(iB)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">Reference</h3>
                  </div>

                  {result.reference.length === 0 && (
                    <div className="et-vc--no-info d-flex justify-content-between align-items-center et-view-border-bottom">
                      <div className="et-vc-left">
                        <p className="et-vc-label">No reference found</p>
                      </div>
                    </div>
                  )}

                  {result.reference.map((i, k) => (
                    <Fragment key={k}>
                      <div className="et-vc-step-info d-flex align-items-center justify-content-between et-view-border-bottom">
                        <div>
                          <span className="et-vc-step me-3">0{++k}</span>
                          <span className="et-vc-step-title">Reference</span>
                        </div>
                      </div>
                      <ul className="et-vc-list row">
                        {[
                          {
                            name: "Full Name",
                            key: "fullName",
                            type: "string",
                          },
                          {
                            name: "Designation",
                            key: "designation",
                            type: "string",
                          },
                          {
                            name: "Email Address",
                            key: "email",
                            type: "string",
                          },
                        ].map((cItem, cKey) => (
                          <li
                            className={`col-md-4 et-view-border-bottom et-view-border-right`}
                            key={cKey}
                          >
                            <div className="et-list-inner">
                              <p className="et-field-name">{cItem.name}</p>
                              <p className="et-field-value">
                                {cItem.key == "email" ? common.hideEmail(i[cItem.key]) : common.readable(cItem.type, i[cItem.key])}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ))}

                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Subscription & Payment
                    </h3>
                  </div>
                  <ul className="et-vc-list row">
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Plan</p>
                        <p className="et-field-value">
                          {result?.plans ? result?.plans?.title : "-"}
                        </p>
                      </div>
                    </li>
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Payment Id</p>
                        <p className="et-field-value">
                          {result?.payments
                            ? result?.payments.transitionId
                            : "-"}
                        </p>
                      </div>
                    </li>
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Expire Date</p>
                        <p className="et-field-value">
                          {result?.payments
                            ? common.dateFormat(
                                result.payments.subscribeEnd,
                                "A"
                              )
                            : "-"}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Fragment>
          )}
          {myModal && (
            <Modal
              isOpen={myModal === "confirm"}
              style={common.modalStyle(70)}
              ariaHideApp={false}
              contentLabel="Modal"
            >
              <div className="container-fluid">
                <div className="row modal-header">
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <p className="modal-title mb-0">
                      This coordinator assigned to below agents
                    </p>
                    <img
                      src={common.loadImg("close-icn.svg")}
                      alt="arrow"
                      onClick={closeModal}
                      className="ed-cp img-fluid"
                    />
                  </div>
                </div>
                <div className="row modal-body">
                  <div className="form-wrap">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        {/* <label>Subject Name</label> */}
                        <ul>
                          {result?.getHisAgents.map((obj, idx) => (
                            <li key={idx}>
                              {idx + 1}. <b>{obj?.firstName}</b> - {obj?.email}
                            </li>
                          ))}
                        </ul>
                        <p className="error-txt">
                          You can't deactive the Coordinator. Because it is
                          assigned to above agents. <br />
                          Remove Reponsible Coordinator for above the agents and
                          then deactivate this coordinator
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn ed-btn-default ed-btn-save-dt"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading !== null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
export default View;
