export const config = {
  base_url: process.env.REACT_APP_BASE_URL,
  public_url: process.env.REACT_APP_PUBLIC_URL,
  // api
  api: {
    isExpired: 401,
    url: process.env.REACT_APP_API_URL,
    cloudImgPath: "https://edutech22.s3.amazonaws.com/",
  },
  mapApiKey: "AIzaSyBInycaDtYijb7243kjYQzthdIxD3ZCXUg",
  // others
  tenantId: 33,
  userId: 5514, // 6325
  cryptKey: "#cErLyPpKt#",
  // elements
  elements: {
    dp: {
      A: "MMMM DD, YYYY",
      B: "MMM DD, YYYY",
      C: "MMM DD, YYYY hh:mm A",
    },
    singleDp: {
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MMMM DD, YYYY",
      },
      autoApply: true,
      autoUpdateInput: false,
      isClearable: true,
    },
    toast: {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  },
  subject: {
    name_min: 3,
    name_max: 30,
  },
};
