import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// includes
import { api, common } from "helpers";
import StatusBar from "elements/statusBar";

function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState(null);

  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "enquiry",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      console.log("========res", res, res.data);
      if (res.status === 200) {
        setResult(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Enquiry View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-view-content-wrap">
                {/* Inner Content */}
                <div className="et-vc-info et-view-border-bottom">
                  {/* <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Personal Information
                    </h3>
                  </div> */}
                  <ul className="et-vc-list row">
                    {console.log("=====result", result)}
                  <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">ReferenceName</p>
                        <p className="et-field-value">
                          {result?.referenceName ? result?.referenceName: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Email</p>
                        <p className="et-field-value">
                          {result?.email ? result?.email: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Phone Number</p>
                        <p className="et-field-value">
                          {result?.phone ? result?.phone: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Already Student</p>
                        <p className="et-field-value">
                          {result?.stuAlready ? result?.stuAlready: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrer Money</p>
                        <p className="et-field-value">
                          {result?.referrerMoney ? result?.referrerMoney: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers1 Name</p>
                        <p className="et-field-value">
                          {result?.referrers1Name ? result?.referrers1Name : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers1 Mobile</p>
                        <p className="et-field-value">
                          {result?.referrers1Mobile ? result?.referrers1Mobile: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers1 Email</p>
                        <p className="et-field-value">
                          {result?.referrers1Email ? result?.referrers1Email : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers2 Name</p>
                        <p className="et-field-value">
                          {result?.referrers2Name ? result?.referrers2Name : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers2 Mobile</p>
                        <p className="et-field-value">
                          {result?.referrers2Mobile ? result?.referrers2Mobile: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers2 Email</p>
                        <p className="et-field-value">
                          {result?.referrers2Email ? result?.referrers2Email : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers3 Name</p>
                        <p className="et-field-value">
                          {result?.referrers3Name ? result?.referrers3Name : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers3 Mobile</p>
                        <p className="et-field-value">
                          {result?.referrers3Mobile ? result?.referrers3Mobile: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Referrers3 Email</p>
                        <p className="et-field-value">
                          {result?.referrers3Email ? result?.referrers3Email : "-"}
                        </p>
                      </div>
                    </li>

                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Salutation</p>
                        <p className="et-field-value">
                          {result?.salutation ? result?.salutation: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">first Name</p>
                        <p className="et-field-value">
                          {result?.fName ? result?.fName: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name"> Last Name</p>
                        <p className="et-field-value">
                          {result?.sName ? result?.sName: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Location</p>
                        <p className="et-field-value">
                          {result?.location ? result?.location: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Domain</p>
                        <p className="et-field-value">
                          {result?.domain ? result?.domain: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Choice</p>
                        <p className="et-field-value">
                          {result?.choice ? result?.choice: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">High Qualification</p>
                        <p className="et-field-value">
                          {result?.hiQua ? result?.hiQua: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Mobile Number</p>
                        <p className="et-field-value">
                          {result?.mobile ? result?.mobile: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">DOB</p>
                        <p className="et-field-value">
                          {result?.dob ? result?.dob: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Age</p>
                        <p className="et-field-value">
                          {result?.age ? result?.age: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Gender</p>
                        <p className="et-field-value">
                          {result?.gender ? result?.gender: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Commitment</p>
                        <p className="et-field-value">
                          {result?.commitment ? result?.commitment: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Had SFE Befofe</p>
                        <p className="et-field-value">
                          {result?.hadSFEBefofe ? result?.hadSFEBefofe: "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Is Deleted</p>
                        <p className="et-field-value">
                          {result?.isDeleted ? "Yes": "No"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Is Verified</p>
                        <p className="et-field-value">
                          {result?.isVerify ? "yes": "No"}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export default View;
