import moment from "moment";
import { common } from "./common";
import { config } from "./config";

// const
let refreshCall = false;
const api_url = config.api.url;
const ids_auth_url = config.api.url + "ids";
const base_url = api_url + "admin";
const common_url = api_url + "common";

// services
const services = {
  // auth
  auth_login: base_url + "/login",

  //ids auth
  ids_login: ids_auth_url + "/login",
  ids_forgot_password: ids_auth_url + "/forgot/password",
  ids_sign_up: ids_auth_url + "/register",
  ids_otp_reset: ids_auth_url + "/reset/otp",
  ids_refresh_token: ids_auth_url + "/refresh/token",
  ids_otp_reset_password: ids_auth_url + "/reset/password",

  // university
  university: base_url + "/university",

  // course
  course: base_url + "/course",
  course_tag: base_url + "/course/tag",

  //cashback
  cashback: base_url + "/cashback",

  //cashback
  cashbackRequest: base_url + "/cashbackRequest",

  // subject
  subject: base_url + "/subject",

  // student
  student: base_url + "/student",
  student_export: base_url + "/student/list/export",

  // agent
  agent: base_url + "/agent",
  agent_student: base_url + "/agent/student",
  agent_export: base_url + "/agent/list/export",

  // sub agent
  subAgent: base_url + "/sub-agents",

  // application
  application: base_url + "/application",
  application_export: base_url + "/application/export",

  // plan
  plan: common_url + "/plan",

  // promocode
  promo: base_url + "/promocode",

  // users
  users: base_url + "/user",

  // setup
  setup: base_url + "/setup",

  //enquiry
  enquiry: base_url + "/enquiry",
  enquiry_export: base_url + "/enquiry/export",

  // blog
  blog_category: base_url + "/blog/category",

  // dashboard
  dashboard: base_url + "/dashboard",

  // common
  common_data: common_url + "/data",
  common_country: common_url + "/country",
  common_state: common_url + "/states",
  common_city: common_url + "/city",
  common_email_check: common_url + "/email/check",
  common_upload: common_url + "/upload",
  common_videoupload: common_url + "/videoupload",
  common_upload_thumbnail: common_url + "/upload/direct/size",
  common_sales_person: common_url + "/salesperson",
  common_test: common_url + "/test",

  // blog
  blogCategory: base_url + "/blog/category",
  blogContent: base_url + "/blog/content",
  // career
  jobCategory: base_url + "/job/category",
  jobSkill: base_url + "/job/skill",
  careerContent: base_url + "/career/content",
  // permission
  permission: base_url + "/permission"
};


// api call
export const api = {
  async call(data = {}, response, final) {
    getData(data);
    // server side rendering
    if (data.ssr) {
      let myPromise = new Promise(function (resolve, reject) {
        fetch(data.url, getOptions(data))
          .then((response) =>
            response.text().then((data) => ({
              status: response.status,
              data: data,
            }))
          )
          .then((result) => {
            if (validateData(result)) {
              try {
                result.data = JSON.parse(result.data);
              } catch (e) {
                result.data = {
                  error: e.toString(),
                };
              }
              notify(data, result);
              resolve(result);
            }
          })
          .catch((err) => {
            let result = {
              status: 404,
              data: {
                error: err.toString(),
              },
            };
            notify(data, result);
            resolve(result);
          });
      });
      return myPromise;
    } else {
      // client side rendering
      fetch(data.url, getOptions(data))
        .then(async (response) => {
          //refresh token
          if (response.status === 401) {
            let refreshTokenResult = await refreshAccessToken();

            if (refreshTokenResult.status === 200) {
              let _data = await refreshTokenResult.json();
              common.localSet("adminAuthData", common.crypt(_data, true));
              const newAccessToken = _data.accessToken;
              const options = getOptions(data);
              options.headers.Authorization = `Bearer ${newAccessToken}`;
              return fetch(data.url, options).then(async (resp) => {
                return resp.text().then((data) => ({
                  status: resp.status,
                  data: data,
                }));
              });
            } else {
              localStorage.clear();
              common.redirect();
              return {
                status: 401,
              };
            }
          } else {
            if (data?.responseType == "file") {
              return response.blob();
            } else {
              return response.text().then((data) => ({
                status: response.status,
                data: data,
              }));
            }
          }
        })
        .then((result) => {
          if (data?.responseType == "file") {
            const url = URL.createObjectURL(result);
            const a = document.createElement("a");
            let fileName = data?.fileName ?? "pickauni";
            a.style.display = "none";
            a.href = url;
            a.download = `${fileName}_${moment().format(
              "YYYY_MM_DD_HH:mm"
            )}.xlsx`; // replace with your desired filename and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            response(result);
          } else {
            if (validateData(result)) {
              try {
                result.data = JSON.parse(result.data);
              } catch (e) {
                result.data = {
                  error: e.toString(),
                };
              }
              notify(data, result);
              response(result);
            }
          }
        })
        .catch((err) => {
          let result = {
            status: 404,
            data: {
              error: err.toString(),
            },
          };
          notify(data, result);
          response(result);
        })
        .finally(() => {
          if (typeof final !== "undefined") {
            final();
          }
        });
    }
  },
};

// support
const getData = (data) => {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.auth = data.auth || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.cType = data.cType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.keyQuery = data.keyQuery || "";
    data.notify = data.notify ?? true;
    // set url
    if (data.type == "third") {
      data.url = data.url + data.query;
    } else {
      data.url = services[data.url] + data.query;
      if (data.keyQuery != "") {
        for (var key in data.keyQuery) {
          data.url = data.url.replace(`:${key}`, data.keyQuery[key]);
        }
      }
    }
    // set body
    if (data.body) {
      if (data.cType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.cType == 2) {
        let bodyParam = [];
        for (var property in data.body) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
};

function getOptions(data) {
  let reqOptions = {
    method: data.method,
    headers: getHeaders(data),
  };

  if (data.body) {
    reqOptions.body = data.body;
  }
  return reqOptions;
}

function getHeaders(data) {
  // default
  let headers = {};

  // content types
  let contentTypes = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
    6: "multipart/form-data",
  };
  if (data.cType !== 6) {
    headers["Content-Type"] = contentTypes[data.cType];
  }

  // extra content types
  let moreHeaderList = {
    rfv: "REST-Framework-Version",
  };
  if (data.moreHead) {
    for (var item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item];
    }
  }

  // authentication
  if (data.auth == "token") {
    let authData = common.getAuth();
    headers.Authorization = "Bearer " + authData?.accessToken; //.token;
  } else if (data.auth == "temp") {
    let tempData = common.tempDataGet();
    headers.Authorization = "Bearer " + tempData?.accessToken;
  } else if (data.auth == "basic") {
    headers.Authorization =
      "Basic " + btoa(data.credentials.param1 + ":" + data.credentials.param2);
  }

  return headers;
}

const validateData = (result) => {
  if (config.api.isExpired != "" && result.status == config.api.isExpired) {
    localStorage.clear();
    common.redirect();
    return false;
  } else {
    return true;
  }
};

const notify = (data, result) => {
  if (data.notify) {
    if (![200, 500, 400, 401].includes(result.status)) {
      common.notify("E", result.data.error);
    }
  }
};

// rToken
const refreshAccessToken = async () => {
  try {
    let authData = common.getAuth();
    const response = await fetch(services["ids_refresh_token"], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: authData?.refreshToken,
      }),
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};
